<template>
  <section id="multiselect">
    <multiselect
      ref="multiselect"
      track-by="value"
      label="label"
      :value="value"
      :options="orded"
      :close-on-select="false"
      :multiple="multiple"
      :disabled="disabled"
      :show-labels="false"
      :searchable="searchable"
      openDirection="bottom"
      class="with-border multiple"
      @change="setMultiselectOption"
      @input="setMultiselectOption"
      @remove="removeMultiselectOption"
      @select="selectedOption"
      placeholder="Selecione um ou mais itens"
    >
      <template slot="placeholder">
        <div>{{ placeholder }}</div>
      </template>
      <template slot="caret">
        <div @mousedown.prevent.stop="toggle" class="chevron">
          <ChevronDown />
        </div>
      </template>

      <template slot="option" slot-scope="props">
        <div>
          {{ props.option.label }}
        </div>
      </template>

      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-show="!isOpen" v-if="values?.length">
          <span v-if="values.some(v => v.value === 'all')">Todos selecionados</span>
          <span v-else-if="values.length > 1">{{ values.length }} Selecionados</span>
          <span v-else>{{ values[0].label }}</span>
        </span>
      </template>

      <template slot="noResult">
        <span>Nenhum resultado encontrado</span>
      </template>
    </multiselect>
  </section>
</template>
<script>
export default {
  name: "MultiSelect",
  components: {
    ChevronDown: () => import("@/assets/icons/chevron-down.svg"),
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    label: String,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Selecionar",
    },
    options: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      selected: null,
    };
  },
  computed: {
    orded() {
      if (!Array.isArray(this.selected)) {
        return this.options;
      }

      if (this.selected.length === 0) {
        return this.options;
      }

      const selectedValues = this.selected.map((option) => option.value);

      return [...this.options].sort((a, b) => {
        if (a.value === "all") {
          return -1; // "all" should come first
        }
        if (b.value === "all") {
          return 1; // "all" should come first
        }
        
        const aIsSelected = selectedValues.includes(a.value) ? -1 : 1;
        const bIsSelected = selectedValues.includes(b.value) ? -1 : 1;
        return aIsSelected - bIsSelected;
      });
    },
  },
  methods: {
    selectedOption(value) {
      this.$emit("select", value);
    },
    removeMultiselectOption(value) {
      this.selected = this.selected.filter((option) => option.value !== value.value);

      this.$emit("remove", value);
    },
    setMultiselectOption(value) {
      if (!Array.isArray(value)) {
        this.selected = value.value;
      } else {
        this.selected = value;
      }

      this.$emit("input", value);
      this.$emit("change", value);
    },
    toggle(event) {
      event.preventDefault();

      if (this.$refs.multiselect.isOpen) {
        this.$refs.multiselect.isOpen = false;
      } else {
        this.$refs.multiselect.isOpen = true;
      }
    },
  },
};
</script>
<style lang="scss">
#multiselect {
  .multiselect__placeholder {
    padding-left: 0 !important;
  }
  .multiselect__single {
    padding-left: 0 !important;
  }
}
</style>
